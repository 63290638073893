import { useState } from "react"
import styled from "styled-components"

import { FeatureFlag } from "src/constants/featureFlags"
import { useFlags } from "src/hooks/useFlags"
import { MText } from "src/ui/MText"

export function DebugBox({
  title,
  topActions,
  data,
  flag = FeatureFlag.DEBUG,
  hidden,
  ...props
}: {
  title?: React.ReactNode
  topActions?: React.ReactNode
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
  data?: any
  flag?: FeatureFlag
  children?: React.ReactNode
  hidden?: boolean
  minimized?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
  props?: any
}) {
  const [minimized, setMinimized] = useState(props.minimized ?? true)
  const flags = useFlags()
  if (!flags.getFlag(flag) || hidden) {
    return null
  }

  return (
    <DataBox {...props.props}>
      <Top>
        <MText variant="bodyS" color="secondary">
          {title ?? "Debug box"}
        </MText>

        {topActions}

        <button type="button" onClick={() => setMinimized(!minimized)}>
          {minimized ? "+" : "-"}
        </button>
      </Top>
      <Contents hidden={minimized}>
        {data !== undefined && <pre>{JSON.stringify(data ?? {}, null, 2)}</pre>}
        <div>{props.children}</div>
        <Actions>
          <button type="button" onClick={() => flags.toggleFlag(flag)}>
            Disable box flag {flag}
          </button>
        </Actions>
      </Contents>
    </DataBox>
  )
}

const DataBox = styled.div`
  max-width: 100%;
  overflow: auto;
  border: 1px solid #ccc;
  pre {
    max-width: 80ch;
  }
  font-family: monospace;
`

const Top = styled.div`
  display: flex;
  justify-content: space-between;
`

const Contents = styled.div<{ hidden?: boolean }>`
  display: ${(props) => (props.hidden ? "none" : "grid")};
  grid-gap: 1rem;
`

const Actions = styled.div`
  display: inline-flex;
  margin: 0 auto;
`
